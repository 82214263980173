import { render, staticRenderFns } from "./guest.vue?vue&type=template&id=2d157d7a&scoped=true"
import script from "./guest.vue?vue&type=script&lang=js"
export * from "./guest.vue?vue&type=script&lang=js"
import style0 from "./guest.vue?vue&type=style&index=0&id=2d157d7a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d157d7a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreAppBar: require('/var/www/html/dev_sitrendi/fe/components/core/AppBar.vue').default,CoreView: require('/var/www/html/dev_sitrendi/fe/components/core/View.vue').default,CoreFooter: require('/var/www/html/dev_sitrendi/fe/components/core/Footer.vue').default})
