import Vue from 'vue';

import {
  LayoutPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  ToastPlugin,
  ModalPlugin,
  IconsPlugin,
  VBModal,
  VBPopover,
  VBTooltip,
  VBScrollspy
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);

Vue.directive('BModal', VBModal);
Vue.directive('BPopover', VBPopover);
Vue.directive('BTooltip', VBTooltip);
Vue.directive('BScrollspy', VBScrollspy);
